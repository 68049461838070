<template>
    <modal title="Стать партнером" ref="modal">
        <div slot="body" class="px-2 pb-2">
            <form @submit.prevent="onSubmit">
                <form-group placeholder="ФИО или наименование организации"
                            class="mb-4"
                            v-model="form.company_name"
                            :message="errors.get('company_name')"
                            :is-invalid="errors.has('company_name')"
                ></form-group>

                <form-group placeholder="Регион оказания услуг"
                            class="mb-4"
                            v-model="form.region"
                            :message="errors.get('region')"
                            :is-invalid="errors.has('region')"
                ></form-group>

                <form-group placeholder="Марка и модель эвакуаторов"
                            class="mb-4"
                            v-model="form.car_name"
                            :message="errors.get('car_name')"
                            :is-invalid="errors.has('car_name')"
                ></form-group>

                <form-group placeholder="Год выпуска"
                            class="mb-4"
                            mask="####"
                            v-model="form.car_year"
                            :message="errors.get('car_year')"
                            :is-invalid="errors.has('car_year')"
                ></form-group>

                <select-group class="mb-4"
                              v-model="form.car_type"
                              :message="errors.get('car_type')"
                              :is-invalid="errors.has('car_type')"
                >
                    <option value="" disabled selected>Тип эвакуаторов</option>
                    <option>Сдвижная гидравлическая</option>
                    <option>Стационарная ручная</option>
                    <option>Манипулятор</option>
                </select-group>

                <form-group class="mb-4"
                            mask="+7 (###) ###-##-##"
                            placeholder="Телефон"
                            v-model="form.phone"
                            :message="errors.get('phone')"
                            :is-invalid="errors.has('phone')"
                ></form-group>

                <form-group placeholder="Электронная почта"
                            class="mb-4"
                            v-model="form.email"
                            :message="errors.get('email')"
                            :is-invalid="errors.has('email')"
                ></form-group>

                <form-group placeholder="Контактное лицо, ФИО"
                            class="mb-4"
                            v-model="form.full_name"
                            :message="errors.get('full_name')"
                            :is-invalid="errors.has('full_name')"
                ></form-group>

                <btn type="submit" class="rounded-pill d-flex mx-auto align-items-center" size="lg" :active="isActive">
                    <span class="spinner-border spinner-border-sm mr-2" role="status" aria-hidden="true" v-if="isActive"></span>Отправить заявку
                </btn>
            </form>
        </div>
    </modal>
</template>
<script>
    import Modal from './Modal'
    import FormGroup from './FormGroup'
    import SelectGroup from './SelectGroup'
    import Btn from './Button'
    import { Form, Errors, axios } from '../utils'

    export default {
        components: {
            Modal,
            FormGroup,
            SelectGroup,
            Btn
        },
        data: () => ({
            form: new Form({
                company_name: '',
                region: '',
                car_name: '',
                car_year: '',
                car_type: '',
                email: '',
                phone: '',
                full_name: ''
            }),
            isActive: false,
            errors: new Errors()
        }),
        methods: {
            onSubmit() {
                this.isActive = true
                axios.post('partnership', this.form.data())
                    .then(() => {
                        this.errors.clear()
                        this.isActive = false
                        this.$refs.modal.hide()
                    })
                    .catch(error => {
                        this.errors.record(error.response.data)
                        this.isActive = false
                    })
            }
        }
    }
</script>