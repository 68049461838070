<template>
    <modal title="Оформление заказа" ref="modal">
        <div class="py-2" slot="body">
            <form @submit.prevent="onSubmit">
                <p class="text-center mb-4">Оставьте свой номер телефона и<br/>мы перезвоним через <b>27 секунд</b>!</p>

                <div class="px-lg-5 mb-4">
                    <form-group mask="8 (###) ###-##-##"
                                placeholder="Номер телефона"
                                v-model="form.phone"
                                :message="errors.get('phone')"
                                :is-invalid="errors.has('phone')"
                                :input-class="{ 'text-center': true }"
                    ></form-group>
                </div>

                <btn type="submit" class="rounded-pill d-flex mx-auto align-items-center" size="lg" :active="isActive" :disabled="!isFormValid">
                    <span class="spinner-border spinner-border-sm mr-2" role="status" aria-hidden="true" v-if="isActive"></span>Оформить заказ
                </btn>
            </form>
        </div>
    </modal>
</template>
<script>
    import _ from 'lodash'
    import Modal from './Modal'
    import FormGroup from './FormGroup'
    import Btn from './Button'
    import { Form, Errors, axios } from '../utils'

    const PHONE_NUMBER_LENGTH = 11

    export default {
        components: {
            Modal,
            FormGroup,
            Btn
        },
        data: () => ({
            form: new Form({
                phone: ''
            }),
            errors: new Errors(),
            isActive: false,
        }),
        computed: {
            phoneNumber() {
                return _.without(this.form.phone.split(''), ' ', '(', ')', '-').join('')
            },
            isFormValid() {
                return this.phoneNumber.length === PHONE_NUMBER_LENGTH
            }
        },
        methods: {
            onSubmit() {
                this.isActive = true;

                const phone = this.phoneNumber;

                axios.post('order', { phone })
                    .then(() => {
                        this.errors.clear()
                        this.$refs.modal.hide()
                        this.$eventHub.$emit('order-created')
                    })
                    .catch(error => {
                        this.errors.record(error.response.data)
                    })
                    .finally(() => {
                        this.isActive = false
                        this.form.reset()
                    })

                // const { result } = jivo_api.startCall(this.phoneNumber)
                // console.log(result)
                //
                // if (result === 'fail') {
                //     this.errors.record({
                //         phone: 'Не удалось оформить заказ. Попробуйте еще раз.'
                //     })
                // } else {
                //     this.$refs.modal.hide()
                //     this.$eventHub.$emit('order-created')
                //     this.form.reset()
                // }
            }
        }
    }
</script>
