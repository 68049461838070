<template>
    <div class="modal fade" tabindex="-1" role="dialog" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered" :class="dialogClasses" role="document">
            <div class="modal-content">
                <div class="modal-header" v-if="!headerHidden">
                    <h6 class="modal-title text-center w-100">{{ title }}</h6>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                    <slot name="body"/>
                </div>
                <div class="modal-footer" v-if="$slots.footer">
                    <slot name="footer"/>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    import $ from 'jquery'

    export default {
        props: {
            visible: Boolean,
            title: String,
            size: {
                required: false,
                type: String,
                validator: value => ['sm', 'lg', 'xl'].indexOf(value) > -1
            },
            headerHidden: {
                required: false,
                type: Boolean,
                default: false
            }
        },
        computed: {
            dialogClasses() {
                let classes = []

                if (this.size) {
                    classes.push('modal-' + this.size)
                }

                return classes
            }
        },
        watch: {
            visible(value) {
                value ? this.show() : this.hide()
            }
        },
        mounted() {
            $(this.$el).modal({
                backdrop: 'static',
                keyboard: false,
                show: this.visible
            })
                .on('show.bs.modal', () => this.$emit('shown'))
                .on('hide.bs.modal', () => this.$emit('hidden'))
        },
        destroyed() {
            $('#' + this.id).modal('dispose')
        },
        methods: {
            show() {
                this.$nextTick(() => $(this.$el).modal('show'))
            },
            hide() {
                this.$nextTick(() => $(this.$el).modal('hide'))
            }
        }
    }
</script>
