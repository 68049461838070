<template>
    <div class="row order-form" v-if="plan">
        <div class="col-12 d-none d-lg-block">
            <div class="d-flex align-items-center justify-content-between mb-4">
                <h4>{{ plan.title }}</h4>

                <div class="d-inline-flex align-items-center">
                    <span class="text-secondary mr-2">об услуге</span>
                    <i class="fas fa-info-circle fa-lg text-primary billing-info" data-toggle="modal" :data-target="'#' + planDescriptionModal.id"></i>
                </div>
            </div>
            <div class="cost d-flex align-items-end">
                <div class="call-cost mr-4" v-if="plan.call_cost > 0">
                    <strong class="d-block">{{ costLabel }}</strong>
                    <span class="d-block text-primary h3 mb-0">{{ `${plan.call_cost} ₽` }}</span>
                </div>
                <span class="additional-cost mr-auto border rounded-pill px-3 py-1" v-if="plan.additional_cost > 0">{{ `Далее ${plan.additional_cost} ₽ / 1 мин`}}</span>
                <div class="country-cost ml-auto text-dark py-1" v-if="plan.country_cost > 0">
                    <span>{{ `${plan.country_cost} ₽ / км за городом` }}</span>
                </div>
            </div>
            <hr class="my-5"/>
            <btn color="primary" size="lg" class="rounded-pill" data-toggle="modal" :data-target="'#' + orderFormModal.id">Заказать</btn>
        </div>
        <div class="col-12 d-block d-lg-none">
            <div class="cost d-flex justify-content-center align-items-end mb-4">
                <div class="call-cost" v-if="plan.call_cost > 0">
                    <strong class="d-block text-center mb-2">{{ costLabel }}</strong>
                    <span class="d-block text-primary h3 mb-0">
                        {{ `${plan.call_cost} ₽` }}
                        <i class="fas fa-info-circle text-primary billing-info ml-2" data-toggle="modal" :data-target="'#' + planDescriptionModal.id"></i>
                    </span>
                </div>
            </div>
            <hr class="my-4"/>
            <div class="cost d-flex justify-content-center align-items-center mb-4">
                <div class="additional-cost border rounded-pill small px-3 py-1 mr-2" v-if="plan.additional_cost > 0">{{ `Далее ${plan.additional_cost} ₽ / 1 мин`}}</div>
                <div class="country-cost text-dark small py-1 ml-2" v-if="plan.country_cost > 0">
                    <span>{{ `${plan.country_cost} ₽ / км за городом` }}</span>
                </div>
            </div>
            <div class="d-flex justify-content-center mb-4">
                <btn color="primary" size="lg" class="rounded-pill px-5" data-toggle="modal" :data-target="'#' + orderFormModal.id">Заказать</btn>
            </div>
        </div>

        <order-form-modal :id="orderFormModal.id"></order-form-modal>
        <plan-description-modal :id="planDescriptionModal.id" :plan="plan" v-if="plan"></plan-description-modal>
    </div>
</template>
<script>
    import _ from 'lodash'
    import Btn from './Button'
    import OrderFormModal from './OrderFormModal'
    import PlanDescriptionModal from './PlanDescriptionModal'

    const BILLING_TYPE_TIME = 'time';
    const BILLING_TYPE_FIX = 'fix';

    export default {
        components: {
            Btn,
            OrderFormModal,
            PlanDescriptionModal
        },
        props: ['plans', 'activePlan'],
        data: () => ({
            billingTypes: [
                BILLING_TYPE_TIME,
                BILLING_TYPE_FIX
            ],
            activeBillingType: BILLING_TYPE_FIX,
            orderFormModal: {
                id: 'order-form-modal'
            },
            planDescriptionModal: {
                id: 'plan-description-modal'
            }
        }),
        watch: {
            plan() {
                this.$emit('update:active-plan', this.plan)
            }
        },
        computed: {
            plan() {
                return _.find(this.plans, ['billing_type', this.activeBillingType])
            },
            costLabel() {
                if (this.plan.billing_type === BILLING_TYPE_TIME) {
                    return 'подача'
                }

                return 'от'
            }
        },
        updated() {
            console.log(this.plans)
        }
    }
</script>