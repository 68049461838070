export default class Errors {
    constructor() {
        this.errors = {}
    }

    /**
     * @param field
     * @returns {boolean}
     */
    has(field) {
        return this.errors.hasOwnProperty(field)
    }

    /**
     * @returns {boolean}
     */
    any() {
        return Object.keys(this.errors).length > 0
    }

    /**
     * @param field
     * @returns {*}
     */
    get(field) {
        if (this.has(field)) {
            return this.errors[field]
        }
    }

    /**
     * @param errors
     */
    record(errors) {
        this.errors = errors
    }

    /**
     * @param field
     */
    clear(field) {
        if (field) {
            delete this.errors[field]

            return
        }

        this.errors = {}
    }
}
