import Vue from 'vue'
import Router from 'vue-router'
import Landing from '../pages/Landing'
import Error from '../pages/Error'

Vue.use(Router)

export default new Router({
    mode: 'history',
    base: '/',
    routes: [
        {
            path: '/',
            name: 'Landing',
            component: Landing
        },
        {
            path: '*',
            name: 'Error',
            component: Error
        }
    ]
})
