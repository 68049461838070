<template>
    <button class="btn" :class="classes" :disabled="active" v-on="$listeners">
        <slot/>
    </button>
</template>
<script>
    export default {
        props: {
            active: {
                type: Boolean,
                default: false
            },
            size: {
                default: 'md',
                validator: (value) => ['md', 'lg'].indexOf(value) > -1
            },
            color: {
                default: 'primary',
                validator: (value) => [
                    'primary',
                    'secondary',
                    'success',
                    'danger',
                    'warning',
                    'info',
                    'light',
                    'dark',
                    'link',
                    'outline-primary'
                ].indexOf(value) > -1
            }
        },
        computed: {
            classes() {
                return [
                    `btn-${this.color}`,
                    `btn-${this.size}`
                ]
            }
        }
    }
</script>
