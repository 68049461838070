<template>
    <modal ref="modal" size="lg" :header-hidden="true">
        <div class="py-4" slot="body">
            <div class="d-flex justify-content-center mb-5">
                <img src="/images/logo.svg" alt="..."/>
            </div>
            <p class="text-center h4 mb-4">Спасибо!</p>
            <p class="text-center mb-4">Ваш заказ принят. В ближайшее время с Вами свяжутся<br/>для информирования и уточнения деталей перевозки.</p>
            <div class="d-flex">
                <btn role="button" class="rounded-pill mx-auto" size="lg" @click="hide">
                    Закрыть
                </btn>
            </div>
        </div>
    </modal>
</template>
<script>
    import Btn from './Button'
    import Modal from './Modal'

    export default {
        components: {
            Btn,
            Modal
        },
        methods: {
            show() {
                this.$refs.modal.show()
            },
            hide() {
                this.$refs.modal.hide()
            }
        }
    }
</script>