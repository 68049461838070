<template>
    <div class="form-group">
        <label :for="id" v-if="label">{{ label }}</label>
        <select class="form-control"
                :class="{ 'is-invalid': isInvalid }"
                v-bind:value="value"
                v-on:input="$emit('input', $event.target.value)"
                :id="id"
        >
            <slot></slot>
        </select>
        <div class="invalid-feedback" v-if="isInvalid">
            {{ message }}
        </div>
    </div>
</template>
<script>
    import UUID from 'uuid/v4'

    export default {
        props: {
            value: {},
            id: {
                type: String,
                required: false,
                default: UUID()
            },
            label: {
                type: String,
                required: false,
            },
            message: {
                type: String,
                default: ''
            },
            isInvalid: {
                type: Boolean,
                default: false
            }
        }
    }
</script>
