<template>
    <section id="map" class="mb-5 mb-lg-3">
        <yandex-map :coords="mapOptions.center"
                    :zoom="mapOptions.zoom"
                    :controls="mapOptions.controls"
                    @map-was-initialized="onMapInit"
                    class="position-absolute h-100 w-100"
        />

        <div class="map-info text-center">
            <h3 class="mb-3 mb-lg-4">Скачайте приложение</h3>
            <div class="d-flex justify-content-center">
                <a href="https://redirect.appmetrica.yandex.com/serve/891527463409086883" class="store-button store-button-appstore d-inline-flex mr-1 mr-lg-3" @click="$ga.event('click', 'IOS')"></a>
                <a href="https://redirect.appmetrica.yandex.com/serve/531238251135026601" class="store-button store-button-playstore d-inline-flex" @click="$ga.event('click', 'Android')"></a>
            </div>
        </div>
    </section>
</template>
<script>
    import { yandexMap as YandexMap } from 'vue-yandex-maps';

    export default {
        components: {
            YandexMap,
        },
        data: () => ({
            mapOptions: {
                center: [54.4054, 39.8097],
                zoom: 5,
                controls: []
            },
            mapIcon: {
                layout: "default#image",
                imageHref: 'images/pin.svg',
                imageSize: [28, 44],
                imageOffset: [-14, -44]
            }
        }),
        methods: {
            onMapInit(map) {
                const objects = [];

                window.Dobrodata.drivers.forEach(driver => {
                    const [lat, long] = driver.point;

                    objects.push(new ymaps.Placemark([lat, long], {}, {
                        iconLayout: 'default#image',
                        iconImageHref: 'images/pin.svg',
                        iconImageSize: [28, 44],
                        iconImageOffset: [-14, -44],
                    }));
                });
                console.log('clusterDisableClickZoom')

                const clusterer = new ymaps.Clusterer({
                    preset: "islands#invertedVioletClusterIcons",
                    clusterDisableClickZoom: true,
                    clusterHideIconOnBalloonOpen: false,
                    geoObjectHideIconOnBalloonOpen: false,
                    hasBalloon: false
                });
                clusterer.options.set("clusterIconColor", "#fec000");
                clusterer.add(objects);
                clusterer.options.set({
                    gridSize: 80,
                    clusterDisableClickZoom: false
                });

                map.geoObjects.add(clusterer);

                map.setBounds(clusterer.getBounds(), {
                    checkZoomRange: true,
                    useMapMargin: true,
                    zoomMargin: [75, 10, 10, 10],
                    // duration: 100
                });

                const zoomControl = new ymaps.control.ZoomControl({
                    options: {
                        position: {
                            bottom: "auto",
                            right: 20,
                            left: "auto",
                            top: 250
                        }
                    }
                });
                map.controls.add(zoomControl);

                map.copyrights.add('Добровоз');
            }
        }
    }
</script>
