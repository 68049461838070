<template>
    <modal title="Об услуге" class="plan-description-modal" size="lg" ref="modal">
        <p slot="body">{{ plan.description }}</p>
    </modal>
</template>
<script>
    import Modal from './Modal'

    export default {
        components: {
            Modal
        },
        props: ['plan']
    }
</script>