<template>
    <layout>
        <header-section></header-section>
        <order-section></order-section>
        <section id="download" class="section mb-md-0">
            <div class="container py-4 py-md-5">
                <div class="row d-flex align-items-center">
                    <div class="col-12 col-md-7 order-1 order-md-0">
                        <h2 class="mb-4">Мобильное приложение</h2>
                        <p class="mb-4">Мобильное приложение позволяет оперативно заказать услугу эвакуации или техпомощи. При этом вы можете отслеживать движение эвакуатора и получать необходимые информационные сообщения о статусе заказа.</p>
                        <p class="discount mb-4">
                            Оставьте отзыв в App Store или Google play и получите<br/><b>скидку 10%</b> на следующую поездку!
                        </p>
                        <div class="d-flex justify-content-center justify-content-lg-start mb-md-4">
                            <a href="https://redirect.appmetrica.yandex.com/serve/891527463409086883" class="store-button store-button-appstore d-inline-flex mr-3" @click="$ga.event('click', 'IOS')"></a>
                            <a href="https://redirect.appmetrica.yandex.com/serve/531238251135026601" class="store-button store-button-playstore d-inline-flex" @click="$ga.event('click', 'Android')"></a>
                        </div>
                    </div>
                    <div class="col-12 col-md-5 order-0 order-md-1">
                        <div class="section-image my-4 mt-lg-0"></div>
                    </div>
                </div>
            </div>
        </section>
        <section id="partners" class="section mb-3 mb-md-0">
            <div class="container py-4 py-md-5 border-bottom-0">
                <div class="row">
                    <div class="col-md-4 mb-3 mb-md-0">
                        <div class="section-image"></div>
                    </div>
                    <div class="col-md-8">
                        <h2 class="mb-4">Станьте партнером</h2>
                        <p class="mb-4">Вы владелец эвакуатора или компании по эвакуации, или техпомощи на дорогах? Вы оказываете услуги добросовестно и качественно? У нас есть предложение, которое позволит вам получать на взаимовыгодных условиях заказы.</p>
                        <p class="mb-4">Станьте нашим партнером! Это стабильный и выгодный сервис доступа к заказам!</p>
                        <div class="d-flex justify-content-center justify-content-lg-start">
                            <button class="btn btn-primary btn-lg rounded-pill" data-toggle="modal" :data-target="'#' + partnershipModal.id">Стать партнером</button>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section id="contacts" class="position-relative">
            <yandex-map :coords="mapOptions.center"
                        :zoom="mapOptions.zoom"
                        :controls="mapOptions.controls"
                        class="position-absolute h-100 w-100"
            >
                <ymap-marker v-for="marker in mapMarkers"
                             :marker-id="marker.id"
                             :coords="marker.coords"
                             :icon="marker.icon"
                             :key="marker.id"
                ></ymap-marker>
            </yandex-map>

            <div class="col-md-6 position-absolute h-100 p-0 bg-primary"></div>
            <div class="container">
                <div class="row">
                    <div class="col-md-6 py-4 py-md-5 text-gray">
                        <h2 class="mb-3">Контакты</h2>
                        <ul class="list-unstyled mb-4 mb-md-5 contacts-list">
                            <li class="mb-2"><i class="fas fa-map-marker-alt"></i>г. Москва, ул. Шипиловская 64к1, офис 147</li>
                            <li class="mb-2"><i class="fas fa-phone-alt"></i><a href="tel:88007075518" @click="$ga.event('click', 'phone number')">8 (800) 707-55-18 (Россия)</a></li>
                            <li class="mb-2"><i class="fas fa-phone-alt"></i><a href="tel:84951774747" @click="$ga.event('click', 'phone number')">8 (495) 177-47-47 (Москва и МО)</a></li>
                            <li class="mb-2"><i class="far fa-envelope"></i><a href="mailto:info@dobrowoz.ru" @click="$ga.event('click', 'email')">info@dobrowoz.ru</a></li>
                        </ul>
                        <h4 class="mb-3">Контроль качества</h4>
                        <ul class="list-unstyled contacts-list">
                            <li><i class="far fa-envelope"></i><a href="mailto:client@dobrowoz.ru">client@dobrowoz.ru</a></li>
                        </ul>
                    </div>
                </div>
            </div>
        </section>

        <partnership-modal :id="partnershipModal.id"></partnership-modal>
    </layout>
</template>
<script>
    import { yandexMap, ymapMarker } from 'vue-yandex-maps'
    import Layout from '../layouts/MainLayout'
    import HeaderSection from '../components/HeaderSection'
    import OrderSection from '../components/OrderSection'
    import PartnershipModal from '../components/PartnershipModal'

    export default {
        components: {
            yandexMap,
            ymapMarker,
            Layout,
            HeaderSection,
            OrderSection,
            PartnershipModal
        },
        data: () => ({
            mapOptions: {
                center: [55.626258, 37.727478],
                zoom: 14,
                controls: []
            },
            mapMarkers: [
                {
                    id: 1,
                    coords: [55.623002, 37.753464],
                    icon: {
                        layout: "default#image",
                        imageHref: 'images/pin.svg',
                        imageSize: [57, 88],
                        imageOffset: [-28, -88]
                    }
                }
            ],
            partnershipModal: {
                id: 'partnership-modal'
            }
        }),
        mounted() {
            $('a[href*="#"]').on('click', (e) => {
                var height = parseInt($('.navbar-main').css('height'));
                e.preventDefault();
                e.stopPropagation();
                var target = $(e.target).attr('href');
                $(target).velocity('scroll', {
                    duration: 1000,
                    offset: -height,
                    easing: 'ease-in-out'
                });
            });
        }
    }
</script>
