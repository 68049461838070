// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import 'bootstrap'
import 'velocity-animate'
import 'select2'

import Vue from 'vue'
import VueMask from 'v-mask'
import YmapPlugin from 'vue-yandex-maps'
import router from './router'
import App from './App'

Vue.use(YmapPlugin, {
    apiKey: window.Dobrovoz.secrets.yandexMapsKey,
    lang: 'ru_RU',
});
Vue.use(VueMask);

Vue.config.productionTip = false

Vue.prototype.$ga = {
    event: (action, category, label, value) => {
        if (Dobrovoz.environment !== 'prod') {
            console.log({ action, category, label, value })
        } else {
            gtag('event', action, {
                'event_category': category,
                'event_label': label,
                'value': value
            })
        }
    }
}

Vue.prototype.$eventHub = new Vue()

/* eslint-disable no-new */
new Vue({
    el: '#app',
    router,
    components: {App},
    template: '<App/>'
})
