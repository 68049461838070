<template>
    <div class="form-group">
        <label :for="id" v-if="label">{{ label }}</label>
        <input class="form-control"
               :id="id"
               :type="type"
               :class="inputClasses"
               :placeholder="placeholder"
               v-mask="mask"
               v-bind:value="value"
               v-on:input="$emit('input', $event.target.value)"
               ref="input"
        >
        <div class="invalid-feedback" v-if="isInvalid">
            {{ message }}
        </div>
    </div>
</template>
<script>
    import UUID from 'uuid/v4'
    import _ from 'lodash'

    export default {
        props: {
            value: {},
            id: {
                type: String,
                required: false,
                default: UUID()
            },
            type: {
                type: String,
                required: false,
                validator: (value) => ['text', 'password', 'tel', 'email', 'number'].indexOf(value) > -1,
                default: 'text'
            },
            placeholder: {
                type: String,
                required: false,
            },
            label: {
                type: String,
                required: false,
            },
            message: {
                type: String,
                default: ''
            },
            isInvalid: {
                type: Boolean,
                default: false
            },
            mask: {
                type: String,
                required: false,
                default: ''
            },
            inputClass: {
                type: Object,
                required: false
            }
        },
        computed: {
            inputClasses() {
                return _.assign(this.inputClass, { 'is-invalid': this.isInvalid })
            }
        }
    }
</script>