<template>
    <section id="order" class="section">
        <div class="container py-4 py-md-5">
            <div class="row d-lg-none">
                <div class="col-12 text-center">
                    <h2 class="mb-3">Добрый тариф</h2>
                    <h4 class="text-primary mb-3" v-if="currentPlan">{{ currentPlan.title }}</h4>
                </div>
            </div>
            <div class="row align-items-center">
                <div class="col-lg-6 order-2 order-lg-1">
                    <div class="swiper-pagination d-none d-xl-block"></div>
                    <h2 class="mb-5 d-none d-lg-block">Добрый тариф</h2>
                    <order-form :plans="activePlans"
                                :active-plan.sync="currentPlan"
                                v-if="activePlans"
                    ></order-form>
                </div>
                <div class="col-lg-6 px-0 px-lg-2 order-1 order-lg-2 mb-4 mb-lg-0">
                    <swiper class="plans-slider" ref="slider"
                            :options="swiperOptions"
                            @slideChange="setActiveServiceType"
                    >
                        <swiper-slide v-for="(slide,index) in slides"
                                      :class="slide.type"
                                      :key="index"
                        >
                            <div class="plan d-flex align-items-center">
                                <div class="plan-title mr-2">{{ slide.title }}</div>
                                <span class="plan-image-wrap position-relative">
                                    <img class="plan-image" :src="slide.image" alt="..."/>
                                </span>
                            </div>
                        </swiper-slide>
                    </swiper>
                </div>
            </div>
        </div>
        <order-created-modal ref="orderCreatedModal"></order-created-modal>
    </section>
</template>
<script>
    import 'swiper/dist/css/swiper.css'
    import { swiper, swiperSlide } from 'vue-awesome-swiper'
    import _ from 'lodash'
    import OrderForm from './OrderForm'
    import OrderCreatedModal from './OrderCreatedModal'

    const TYPE_TRUCK = 'truck'
    const TYPE_MANIPULATOR = 'manipulator'
    const TYPE_HELP = 'help'

    export default {
        components: {
            swiper,
            swiperSlide,
            OrderForm,
            OrderCreatedModal
        },
        data: () => ({
            swiperOptions: {
                allowTouchMove: false,
                centerInsufficientSlides: true,
                centeredSlides: true,
                direction: 'vertical',
                // effect: 'slide',
                loop: true,
                mousewheel: false,
                paginationClickable: true,
                preventClicks: false,
                preventClicksPropagation: false,
                slidesPerView: 3,
                slidesPerGroup: 1,
                slideToClickedSlide: true,
                spaceBetween: 0,
                speed: 0,

                pagination: {
                    el: '.swiper-pagination',
                    clickable: true,
                },

                breakpoints: {
                    992: {
                        allowTouchMove: true,
                        direction: 'horizontal',
                        slidesPerView: 2,
                        slidesPerGroup: 1,
                        loop: false,
                        mousewheel: true,
                        spaceBetween: 30,
                        speed: 500
                    }
                }
            },
            slides: [
                {
                    type: TYPE_TRUCK,
                    image: '/images/plans/truck.png',
                    title: 'Эвакуатор'
                },
                {
                    type: TYPE_MANIPULATOR,
                    image: '/images/plans/manipulator.png',
                    title: 'Манипулятор'
                },
                {
                    type: TYPE_HELP,
                    image: '/images/plans/help.png',
                    title: 'Тех-помощь'
                }
            ],
            activeServiceType: null,
            currentPlan: null
        }),
        mounted() {
            this.setActiveServiceType()

            this.$eventHub.$on('order-created', () => this.$refs.orderCreatedModal.show())
        },
        computed: {
            swiper() {
                return this.$refs.slider.swiper
            },
            activePlans() {
                if (!this.activeServiceType) { return [] }
                return _.groupBy(window.Dobrodata.plans, 'service_type')[this.activeServiceType]
            }
        },
        methods: {
            setActiveServiceType() {
                this.activeServiceType = this.slides[this.swiper.realIndex].type
            }
        }
    }
</script>